import { createAction } from 'redux-actions';

import notifyMessages from '../../config/notifyMessages';

import * as APIService from './api';

export const getNewsRequest = createAction('GET/NEWS/REQUEST');
export const getNewsSuccess = createAction('GET/NEWS/SUCCESS');
export const getNewsFailure = createAction('GET/NEWS/FAILURE');

export const createNewsRequest = createAction('CREATE/NEWS/REQUEST');
export const createNewsSuccess = createAction('CREATE/NEWS/SUCCESS');
export const createNewsFailure = createAction('CREATE/NEWS/FAILURE');

export const updateNewsRequest = createAction('UPDATE/NEWS/REQUEST');
export const updateNewsSuccess = createAction('update/NEWS/SUCCESS');
export const updateNewsFailure = createAction('update/NEWS/FAILURE');

export const deleteNewsRequest = createAction('DELETE/NEWS/REQUEST');
export const deleteNewsSuccess = createAction('DELETE/NEWS/SUCCESS');
export const deleteNewsFailure = createAction('DELETE/NEWS/FAILURE');

export const getNews = (page, pageSize, draft, filters) => async (dispatch) => {
  dispatch(getNewsRequest());
  try {
    const res = await APIService.getNews(page, pageSize, draft, filters);
    dispatch(getNewsSuccess(res));
  } catch (e) {
    console.error(e);
    dispatch(getNewsFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};

export const createNews = data => async (dispatch) => {
  dispatch(createNewsRequest());
  try {
    const res = await APIService.createNews(data);
    dispatch(createNewsSuccess({ ...data, newsId: res.id }));
  } catch (e) {
    if (e.response.status === 400) {
      dispatch(createNewsFailure({ status: 'error', message: notifyMessages.errors.emptyFields }));
    } else {
      dispatch(createNewsFailure({ status: 'error', message: notifyMessages.errors.common }));
    }
  }
};

export const updateNews = (id, data) => async (dispatch) => {
  dispatch(updateNewsRequest());
  try {
    await APIService.updateNews(id, { ...data, newsId: id });
    dispatch(updateNewsSuccess({ id, data }));
  } catch (e) {
    console.error(e);
    if (e.response.status === 400) {
      dispatch(updateNewsFailure({ status: 'error', message: notifyMessages.errors.emptyFields }));
    } else {
      dispatch(updateNewsFailure({ status: 'error', message: notifyMessages.errors.common }));
    }
  }
};

export const deleteNews = id => async (dispatch) => {
  dispatch(deleteNewsRequest());
  try {
    await APIService.deleteNews(id);
    dispatch(deleteNewsSuccess({ id }));
  } catch (e) {
    console.error(e);
    dispatch(deleteNewsFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};
