import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ukUA from 'antd/es/locale/uk_UA';

import * as routes from '../config/routes';

import Login from './Auth';
import News from './News';
import Informations from './Informations';

const App = () => (
  /* eslint-disable-next-line */
  <ConfigProvider locale={ukUA}>
    <Switch>
      <Route exact path={routes.ROOT}>
        <Redirect to={routes.NEWS} />
      </Route>
      <Route path={routes.LOGIN} component={Login} />
      <Route path={routes.NEWS} component={News} />
      <Route path={routes.INFORMATIONS} component={Informations} />
      }
    </Switch>
  </ConfigProvider>
);

export default App;
