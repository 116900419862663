import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

const authInitState = {
  isAuth: false,
  user: {},
  errorMsg: null,
};
const data = handleActions({
  [actions.logInSuccess]: () => ({ isAuth: true, user: {}, errorMsg: null }),
  [actions.logInFailure]: (state, { payload: { errorMsg } }) => ({
    isAuth: false, user: {}, errorMsg,
  }),
  [actions.logOut]: () => authInitState,
}, authInitState);

const login = handleActions({
  [actions.logInRequest]: () => 'request',
  [actions.logInSuccess]: () => 'success',
  [actions.logInFailure]: () => 'failure',
}, '');

const fetching = combineReducers({ login });

export default combineReducers({ data, fetching });
