import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

const list = handleActions({
  [actions.getNewsSuccess]: (_, { payload }) => payload.news,
  [actions.createNewsSuccess]: (state, { payload }) => [payload, ...state],
  [actions.updateNewsSuccess]: (state, { payload: { id, data } }) => (
    state.map(news => (news.newsId === id ? { ...news, ...data } : news))
  ),
  [actions.deleteNewsSuccess]: (state, { payload: { id } }) => (
    state.filter(news => news.newsId !== id)
  ),
}, []);

const total = handleActions({
  [actions.getNewsSuccess]: (_, { payload: { count, news } }) => count || news.length,
}, 0);

const getNews = handleActions({
  [actions.getNewsRequest]: () => 'request',
  [actions.getNewsSuccess]: () => 'success',
  [actions.getNewsFailure]: () => 'failure',
}, '');

const createNews = handleActions({
  [actions.createNewsRequest]: () => 'request',
  [actions.createNewsSuccess]: () => 'success',
  [actions.createNewsFailure]: () => 'failure',
}, '');

const updateNews = handleActions({
  [actions.updateNewsRequest]: () => 'request',
  [actions.updateNewsSuccess]: () => 'success',
  [actions.updateNewsFailure]: () => 'failure',
}, '');

const deleteNews = handleActions({
  [actions.deleteNewsRequest]: () => 'request',
  [actions.deleteNewsSuccess]: () => 'success',
  [actions.deleteNewsFailure]: () => 'failure',
}, '');

const fetching = combineReducers({
  getNews,
  createNews,
  updateNews,
  deleteNews,
});

export default combineReducers({ list, total, fetching });
