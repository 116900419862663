import { client } from '../../config/configurateAPIService';

import queryBuilder from '../../utils/queryBuilder';

export const getActives = async () => {
  const res = await client.get('/admin/activities');

  if (res.status !== 200) {
    throw new Error(`Fetch actives failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Fetch actives failed. Data not returned');
  }

  return res.data;
};

export const uploadFile = async (file, params) => {
  const res = await client.post(queryBuilder('/admin/uploader', params), file);

  if (res.status !== 200) {
    throw new Error(`Upload image failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Upload image failed. Data not returned');
  }

  return res.data;
};
