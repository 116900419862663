import { createAction } from 'redux-actions';

import * as APIService from './api';

export const getActivesRequest = createAction('GET/ACTIVES/REQUEST');
export const getActivesSuccess = createAction('GET/ACTIVES/SUCCESS');
export const getActivesFailure = createAction('GET/ACTIVES/FAILURE');

export const getActives = () => async (dispatch) => {
  dispatch(getActivesRequest());
  try {
    const res = await APIService.getActives();
    dispatch(getActivesSuccess(res.activities));
  } catch (e) {
    console.error(e);
    dispatch(getActivesFailure(e));
  }
};
