import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

const list = handleActions({
  [actions.getInformationsSuccess]: (_, { payload }) => payload,
  [actions.createInformationSuccess]: (state, { payload }) => [payload, ...state],
  [actions.updateInformationSuccess]: (state, { payload: { id, data } }) => (
    state.map(info => (info.infoArticleId === id ? { ...info, ...data } : info))
  ),
  [actions.deleteInformationSuccess]: (state, { payload: { id } }) => (
    state.filter(info => info.infoArticleId !== id)
  ),
}, []);

const getInformations = handleActions({
  [actions.getInformationsRequest]: () => 'request',
  [actions.getInformationsSuccess]: () => 'success',
  [actions.getInformationsFailure]: () => 'failure',
}, '');

const createInformation = handleActions({
  [actions.createInformationRequest]: () => 'request',
  [actions.createInformationSuccess]: () => 'success',
  [actions.createInformationFailure]: () => 'failure',
}, '');

const updateInformation = handleActions({
  [actions.updateInformationRequest]: () => 'request',
  [actions.updateInformationSuccess]: () => 'success',
  [actions.updateInformationFailure]: () => 'failure',
}, '');

const deleteInformation = handleActions({
  [actions.deleteInformationRequest]: () => 'request',
  [actions.deleteInformationSuccess]: () => 'success',
  [actions.deleteInformationFailure]: () => 'failure',
}, '');

const fetching = combineReducers({
  getInformations,
  createInformation,
  updateInformation,
  deleteInformation,
});

export default combineReducers({ list, fetching });
