import React from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Tabs,
  notification,
} from 'antd';

import notifyMessages from '../../../config/notifyMessages';
import getFileName from '../../../utils/getFileNameFromUrl';

import styles from './Informations.module.scss';

class InformationForm extends React.Component {
  componentDidMount() {
    this.props.getActives();
  }

  handleSubmint = (e) => {
    e.preventDefault();
    const {
      form,
      data: { infoArticleId },
      createInformation,
      updateInformation,
    } = this.props;

    form.validateFields((err) => {
      if (!err) {
        infoArticleId
          ? updateInformation(infoArticleId)
          : createInformation();
      } else {
        notification.error({
          message: notifyMessages.errors.title,
          description: notifyMessages.errors.emptyTranslationFields,
          placement: 'topLeft',
          duration: 5,
        });
      }
    });
  }

  renderTranslationFields = (lang) => {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label="Розділ">
              {getFieldDecorator(`title_${lang}`, { rules: [{ required: true, message: 'Потрібно заповнити це поле' }] })(
                <Input placeholder="Розділ" />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label="Текст розділу">
              {getFieldDecorator(`description_${lang}`)(
                <Input.TextArea rows={15} placeholder="Текст розділу" />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      form,
      actives,
      cancel,
      data: { infoArticleId, pdf },
      deleteInformation,
      getActivesFetchStatus,
      handleChangeFile,
      createInformationFetchStatus,
      updateInformationFetchStatus,
      deleteInformationFetchStatus,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.handleSubmint} className={styles.form}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label="Аудиторія">
              {getFieldDecorator('actives', { rules: [{ required: true, message: 'Потрібно заповнити це поле' }] })(
                <Select
                  placeholder="Виберіть аудиторію"
                  loading={getActivesFetchStatus === 'request'}
                  maxTagCount={5}
                  mode="multiple"
                >
                  <Select.Option value={0}>Всі</Select.Option>
                  {actives.map(active => (
                    <Select.Option key={active.id} value={active.id}>{active.name}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Tabs defaultActiveKey="ua">
          <Tabs.TabPane key="ua" tab="UA">
            {this.renderTranslationFields('ua')}
          </Tabs.TabPane>
          <Tabs.TabPane key="ru" tab="RU">
            {this.renderTranslationFields('ru')}
          </Tabs.TabPane>
          <Tabs.TabPane key="en" tab="EN">
            {this.renderTranslationFields('en')}
          </Tabs.TabPane>
        </Tabs>
        <Upload
          accept=".pdf"
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
          fileList={
            pdf
          }
          customRequest={handleChangeFile('pdf')}
        >
          <Button>Завантажити PDF</Button>
        </Upload>
        <div className={styles.actions}>
          <React.Fragment>
            <Button type="primary" htmlType="submit" loading={[createInformationFetchStatus, updateInformationFetchStatus].includes('request')}>
              {infoArticleId ? 'Оновити' : 'Створити'}
            </Button>
          </React.Fragment>
          {infoArticleId && (
            <Button
              type="danger"
              htmlType="button"
              loading={deleteInformationFetchStatus === 'request'}
              onClick={deleteInformation(infoArticleId)}
            >
              Видалити
            </Button>
          )}
          <Button htmlType="button" onClick={cancel}>Закрити</Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({
  name: 'news',
  onFieldsChange: (props, changedFields) => {
    props.handleChange(changedFields);
  },
  mapPropsToFields: ({ data }) => ({
    title_ru: Form.createFormField({ ...data.title_ru, value: data.title_ru.value }),
    description_ru: Form.createFormField({ ...data.description_ru, value: data.description_ru.value }),

    title_ua: Form.createFormField({ ...data.title_ua, value: data.title_ua.value }),
    description_ua: Form.createFormField({ ...data.description_ua, value: data.description_ua.value }),

    title_en: Form.createFormField({ ...data.title_en, value: data.title_en.value }),
    description_en: Form.createFormField({ ...data.description_en, value: data.description_en.value }),

    actives: Form.createFormField({ ...data.actives, value: data.actives.value }),
  }),
})(InformationForm);
