import { createAction } from 'redux-actions';

import notifyMessages from '../../config/notifyMessages';

import * as APIService from './api';

export const getInformationsRequest = createAction('GET/INFORMATIONS/REQUEST');
export const getInformationsSuccess = createAction('GET/INFORMATIONS/SUCCESS');
export const getInformationsFailure = createAction('GET/INFORMATIONS/FAILURE');

export const createInformationRequest = createAction('CREATE/INFORMATION/REQUEST');
export const createInformationSuccess = createAction('CREATE/INFORMATION/SUCCESS');
export const createInformationFailure = createAction('CREATE/INFORMATION/FAILURE');

export const updateInformationRequest = createAction('UPDATE/INFORMATION/REQUEST');
export const updateInformationSuccess = createAction('UPDATE/INFORMATION/SUCCESS');
export const updateInformationFailure = createAction('UPDATE/INFORMATION/FAILURE');

export const deleteInformationRequest = createAction('DELETE/INFORMATION/REQUEST');
export const deleteInformationSuccess = createAction('DELETE/INFORMATION/SUCCESS');
export const deleteInformationFailure = createAction('DELETE/INFORMATION/FAILURE');

export const getInformations = activeId => async (dispatch) => {
  dispatch(getInformationsRequest());
  try {
    const res = await APIService.getInformations();
    dispatch(getInformationsSuccess(res.articles));
  } catch (e) {
    console.error(e);
    dispatch(getInformationsFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};

export const createInformation = data => async (dispatch) => {
  dispatch(createInformationRequest());
  try {
    const res = await APIService.createInformation({ ...data });
    dispatch(createInformationSuccess({ infoArticleId: res.id, ...data }));
  } catch (e) {
    console.error(e);
    dispatch(createInformationFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};

export const updateInformation = (id, data) => async (dispatch) => {
  dispatch(updateInformationRequest());
  try {
    await APIService.updateInformation(id, { ...data });
    dispatch(updateInformationSuccess({ id, data }));
  } catch (e) {
    console.error(e);
    dispatch(updateInformationFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};

export const deleteInformation = id => async (dispatch) => {
  dispatch(deleteInformationRequest());
  try {
    await APIService.deleteInformation(id);
    dispatch(deleteInformationSuccess({ id }));
  } catch (e) {
    console.error(e);
    dispatch(deleteInformationFailure({ status: 'error', message: notifyMessages.errors.common }));
  }
};
