import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Layout, Button } from 'antd';

import { logOut } from '../../../Auth/actions';

import * as routes from '../../../../config/routes';

import styles from './Sidebar.module.scss';

const links = [
  { name: 'Новини', route: routes.NEWS },
  { name: 'Інформація', route: routes.INFORMATIONS },
];

const Sidebar = ({ logOut }) => (
  <Layout.Sider theme="light" width="300" style={{ height: '100vh' }}>
    <div className={styles.sidebar}>
      <ul className={styles.navs}>
        {links.map(link => (
          <li key={link.name}>
            <NavLink
              key={link.route}
              to={link.route}
              className={styles.link}
              activeClassName={styles.activeLink}
            >
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <Button type="link" style={{ marginTop: 'auto' }} onClick={logOut}>Вийти</Button>
    </div>
  </Layout.Sider>
);

export default connect(null, { logOut })(Sidebar);
