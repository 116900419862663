import React from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Tabs,
  Table,
  DatePicker,
  Drawer,
  Button,
  Input,
  Icon,
} from 'antd';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

import {
  getNews,
  createNews,
  updateNews,
  deleteNews,
} from '../actions';
import { getActives } from '../../common/actions';
import { activesSelector, getActivesFetchStatusSelector } from '../../common/selectors';
import {
  newsSelector,
  totalNewsSelector,
  getNewsFetchSelector,
  createNewsFetchSelector,
  updateNewsFetchSelector,
  deleteNewsFetchSelector,
} from '../selectors';
import calculateResizeResolution from '../../../utils/calculateResizeResolution';

import { uploadFile } from '../../common/api';
import getFileName from '../../../utils/getFileNameFromUrl';

import Sidebar from '../../common/components/Sidebar';
import NewsForm from './NewsForm';

import styles from './News.module.scss';

class News extends React.Component {
  state = {
    isOpenNewsForm: false,
    selectedNewsId: '',
    currentLang: 'ua',
    selectedTab: 'draft',
    newsData: {
      title_ru: { value: '' },
      body_ru: { value: '' },

      title_ua: { value: '' },
      body_ua: { value: '' },

      title_en: { value: '' },
      body_en: { value: '' },

      actives: { value: [] },
      startDate: { value: moment() },
      coverImageUrl: [],
      draft: true,
    },
    filters: {
      findTitle: null,
      findDate: null,
    },
  }

  componentDidMount() {
    this.props.getNews(0, 20, true);
    this.props.getActives();
  }

  componentDidUpdate(prevProps) {
    const {
      createNewsFetchStatus,
      updateNewsFetchStatus,
      deleteNewsFetchStatus,
    } = this.props;

    const isCreateSuccess = prevProps.createNewsFetchStatus !== 'success' && createNewsFetchStatus === 'success';
    const isUpdateSuccess = prevProps.updateNewsFetchStatus !== 'success' && updateNewsFetchStatus === 'success';
    const isDeleteSuccess = prevProps.deleteNewsFetchStatus !== 'success' && deleteNewsFetchStatus === 'success';

    if (isCreateSuccess || isUpdateSuccess || isDeleteSuccess) {
      this.closeNewsForm();
    }
  }

  selectTab = (tabKey) => {
    const { filters } = this.state;
    this.props.getNews(0, 20, tabKey === 'draft', filters);
    this.setState({ selectedTab: tabKey });
  }

  openNewsForm = () => this.setState({ isOpenNewsForm: true });

  changeLang = ({ target }) => {
    this.setState({ currentLang: target.value });
  }

  showNewsInfo = data => () => {
    this.setState({
      isOpenNewsForm: true,
      selectedNewsId: data.newsId,
      newsData: {
        ...data,

        title_ru: { value: data.title_ru },
        body_ru: { value: data.body_ru },

        title_ua: { value: data.title_ua },
        body_ua: { value: data.body_ua },

        title_en: { value: data.title_en },
        body_en: { value: data.body_en },

        actives: {
          value: data.actives.some(active => active.id === 0)
            ? [0]
            : data.actives.map(active => active.id),
        },
        coverImageUrl: data.coverImageUrl
          ? [{
            uid: '-1', name: getFileName(data.coverImageUrl), status: 'done', url: data.coverImageUrl,
          }]
          : [],
        startDate: { value: moment(data.sendAt).utc() },
      },
    });
  }

  closeNewsForm = () => {
    this.setState({
      isOpenNewsForm: false,
      selectedNewsId: '',
      newsData: {
        actives: { value: [] },
        startDate: { value: moment().utc() },

        title_ru: { value: '' },
        body_ru: { value: '' },

        title_ua: { value: '' },
        body_ua: { value: '' },

        title_en: { value: '' },
        body_en: { value: '' },

        coverImageUrl: [],
        draft: true,
      },
    });
  }

  handleChange = (changedFields) => {
    this.setState(({ newsData }) => ({ newsData: { ...newsData, ...changedFields } }));
  }

  handleChangeFile = async ({ file, onSuccess }) => {
    /* eslint-disable */
    const data = new FormData();
    const img = new Image();
    const _URL = window.URL || window.webkitURL;

    this.setState(state => ({
      newsData: {
        ...state.newsData,
        coverImageUrl: [{ uid: '-1', name: file.name, status: 'uploading' }],
      },
    }));

    img.src = _URL.createObjectURL(file);
    img.onload = async () => {
      data.append('image', file);

      try {
        const { width, height } = img;
        const params = {
          name: file.name,
          type: 'postImage',
          ...calculateResizeResolution(width, height),
        };

        const { url } = await uploadFile(data, params);
        this.setState(state => ({
          newsData: {
            ...state.newsData,
            coverImageUrl: [{
              uid: '-1', name: file.name, status: 'done', url,
            }],
          },
        }));
        onSuccess();
      } catch (e) {
        console.error(e);
      }
    }
    /* eslint-enable */
  }

  createNews = () => {
    // const { actives } = this.props;
    const { newsData } = this.state;

    const data = {
      title_ua: newsData.title_ua.value,
      body_ua: newsData.body_ua.value,

      title_ru: newsData.title_ru.value,
      body_ru: newsData.body_ru.value,

      title_en: newsData.title_en.value,
      body_en: newsData.body_en.value,
      // TODO: переместить логику выбора всех аудиторий в селект
      actives: newsData.actives.value.includes(0)
        ? [{ id: 0 }]
        : newsData.actives.value.map(id => ({ id })),
      sendAt: newsData.startDate.value ? newsData.startDate.value.valueOf() : '',
      coverImageUrl: newsData.coverImageUrl[0] ? newsData.coverImageUrl[0].url : '',
      draft: false,
    };

    this.props.createNews(data);
  }

  updateNews = (newsId, isDraft) => () => {
    // const { actives } = this.props;
    const { newsData } = this.state;

    const data = {
      title_ua: newsData.title_ua.value,
      body_ua: newsData.body_ua.value,

      title_ru: newsData.title_ru.value,
      body_ru: newsData.body_ru.value,

      title_en: newsData.title_en.value,
      body_en: newsData.body_en.value,
      // actives: newsData.actives.value.includes('all')
      //   ? actives
      //   : newsData.actives.value.map(id => actives.find(active => active.id === id)),
      actives: newsData.actives.value.includes(0)
        ? [{ id: 0 }]
        : newsData.actives.value.map(id => ({ id })),
      sendAt: newsData.startDate.value ? newsData.startDate.value.valueOf() : '',
      coverImageUrl: newsData.coverImageUrl[0] ? newsData.coverImageUrl[0].url : '',
      draft: isDraft,
    };

    this.props.updateNews(newsId, data);
  }

  saveAsDraft = () => {
    const { newsData } = this.state;

    const data = {
      title_ua: newsData.title_ua.value,
      body_ua: newsData.body_ua.value,
      title_ru: newsData.title_ru.value,
      body_ru: newsData.body_ru.value,
      title_en: newsData.title_en.value,
      body_en: newsData.body_en.value,
      // TODO: переместить логику выбора всех аудиторий в селект
      actives: newsData.actives.value.includes(0)
        ? [{ id: 0 }]
        : newsData.actives.value.map(id => ({ id })),
      sendAt: newsData.startDate.value ? newsData.startDate.value.valueOf() : '',
      coverImageUrl: newsData.coverImageUrl[0] ? newsData.coverImageUrl[0].url : '',
      draft: true,
    };

    this.props.createNews(data);
  }

  deleteNews = id => () => {
    this.props.deleteNews(id);
  }

  // NOTE: see antd docs for the table
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginBottom: 5 }}>Мінімум 3 символи</span>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => (selectedKeys[0].length > 2 ? this.handleSearch(selectedKeys, confirm) : {})}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Пошук
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Скинути
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => (
      record[dataIndex]
        .toString()
        .toLowerCase()
        .trim()
        .includes(value.toLowerCase())
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    const { selectedTab, filters } = this.state;
    const [findTitle] = selectedKeys;
    const isDraft = selectedTab === 'draft';

    if (findTitle.length > 2) {
      confirm();
      this.props.getNews(0, 20, isDraft, { ...filters, findTitle });
      this.setState(state => ({
        filters: {
          ...state.filters,
          findTitle,
        },
      }));
    }
  }

  handleReset = (clearFilters) => {
    clearFilters();

    const { selectedTab, filters } = this.state;
    const isDraft = selectedTab === 'draft';

    this.props.getNews(0, 20, isDraft, {
      ...filters,
      findTitle: null,
    });
    this.setState(state => ({
      filters: {
        ...state.filters,
        findTitle: null,
      },
    }));
  }

  renderAddNewsButton = () => (
    <Button type="primary" onClick={this.openNewsForm}>
      <Icon type="plus" />
      Добавити новину
    </Button>
  )

  render() {
    const {
      savedNews,
      totalNews,
      getActivesFetchStatus,
      publishedNews,
      getNewsFetchStatus,
      createNewsFetchStatus,
      updateNewsFetchStatus,
      deleteNewsFetchStatus,
    } = this.props;
    const {
      newsData,
      filters,
      selectedTab,
      currentLang,
      selectedNewsId,
      isOpenNewsForm,
    } = this.state;

    const columns = [
      {
        title: 'Новина',
        dataIndex: 'title_ua',
        key: 'title_ua',
        ...this.getColumnSearchProps('title_ua'),
        sorter: (a, b) => a.title_ua.localeCompare(b.title_ua),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Дата створення',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).utc().format('DD-MM-YYYY HH:mm'),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <DatePicker
            format="DD-MM-YYYY"
            value={selectedKeys[0]}
            onChange={(date) => {
              const isDraft = selectedTab === 'draft';
              const findDate = date ? date.utc().format('YYYY-MM-DD') : null;

              setSelectedKeys(date ? [date] : []);
              this.setState(state => ({
                filters: {
                  ...state.filters,
                  findDate,
                },
              }));
              this.props.getNews(0, 20, isDraft, { ...filters, findDate });
              confirm();
            }}
          />
        ),
        onFilter: (value, record) => moment(record.createdAt).isSame(value, 'day'),
        sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Дата публікації',
        dataIndex: 'sendAt',
        key: 'sendAt',
        render: sendAt => moment(sendAt).utc().format('DD-MM-YYYY HH:mm'),
        // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        //   <DatePicker
        //     format="DD-MM-YYYY"
        //     value={selectedKeys[0]}
        //     onChange={(date) => {
        //       setSelectedKeys(date ? [date] : []);
        //       confirm();
        //     }}
        //   />
        // ),
        // onFilter: (value, record) => moment(record.sendAt).isSame(value, 'day'),
        sorter: (a, b) => moment(a.sendAt).diff(moment(b.sendAt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Аудиторія',
        dataIndex: 'actives',
        key: 'actives',
        ellipsis: true,
        // filters: actives.map(active => ({ text: active.name, value: active.id })),
        // onFilter: (value, record) => record.actives.includes(value),
        render: actives => (
          actives.some(active => active.id === 0)
            ? 'Всі'
            : actives.map(active => active.name).join(', ')
        ),
      },
    ];

    return (
      <React.Fragment>
        <Layout style={{ height: '100%' }}>
          <Sidebar />
          <Layout>
            <Layout.Content className={styles.news}>
              <Tabs defaultActiveKey="draft" onChange={this.selectTab} tabBarExtraContent={this.renderAddNewsButton()}>
                <Tabs.TabPane key="draft" tab="Збережені, але не опубліковані новини">
                  <Table
                    columns={columns}
                    dataSource={savedNews}
                    tableLayout="fixed"
                    scroll={{ y: 'calc(100vh - 220px)' }}
                    loading={[getActivesFetchStatus, getNewsFetchStatus, createNewsFetchStatus].includes('request')}
                    className={styles.table}
                    rowKey={() => uniqueId()}
                    onRow={record => ({ onClick: this.showNewsInfo(record) })}
                    pagination={{
                      pageSize: 20,
                      size: 'small',
                      total: totalNews,
                      onChange: (page, size) => this.props.getNews((page - 1) * 20, size, selectedTab === 'draft', filters),
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key="published" tab="Опубліковані новини">
                  <Table
                    columns={columns}
                    dataSource={publishedNews}
                    tableLayout="fixed"
                    scroll={{ y: 'calc(100vh - 220px)' }}
                    loading={[getActivesFetchStatus, getNewsFetchStatus, createNewsFetchStatus].includes('request')}
                    className={styles.table}
                    rowKey={() => uniqueId()}
                    onRow={record => ({ onClick: this.showNewsInfo(record) })}
                    pagination={{
                      pageSize: 20,
                      size: 'small',
                      total: totalNews,
                      onChange: (page, size) => this.props.getNews((page - 1) * 20, size, selectedTab === 'draft', filters),
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Layout.Content>
          </Layout>
        </Layout>
        <Drawer
          title="Новина"
          width="50%"
          visible={isOpenNewsForm}
          bodyStyle={{ height: 'calc(100% - 55px)', position: 'relative' }}
          destroyOnClose
          onClose={this.closeNewsForm}
        >
          <NewsForm
            selectedNewsId={selectedNewsId}
            data={newsData}
            currentLang={currentLang}
            actives={this.props.actives}
            getActivesFetchStatus={getActivesFetchStatus}
            changeLang={this.changeLang}
            createNews={this.createNews}
            saveNews={this.saveAsDraft}
            updateNews={this.updateNews}
            deleteNews={this.deleteNews}
            handleChange={this.handleChange}
            handleChangeFile={this.handleChangeFile}
            getActives={this.props.getActives}
            cancel={this.closeNewsForm}
            createNewsFetchStatus={createNewsFetchStatus}
            updateNewsFetchStatus={updateNewsFetchStatus}
            deleteNewsFetchStatus={deleteNewsFetchStatus}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const news = newsSelector(state);

  return {
    savedNews: news.filter(item => item.draft),
    publishedNews: news.filter(item => !item.draft),
    totalNews: totalNewsSelector(state),
    actives: activesSelector(state),
    getNewsFetchStatus: getNewsFetchSelector(state),
    getActivesFetchStatus: getActivesFetchStatusSelector(state),
    createNewsFetchStatus: createNewsFetchSelector(state),
    updateNewsFetchStatus: updateNewsFetchSelector(state),
    deleteNewsFetchStatus: deleteNewsFetchSelector(state),
  };
};

export default connect(mapStateToProps, {
  getNews,
  createNews,
  updateNews,
  deleteNews,
  getActives,
})(News);
