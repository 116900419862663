import { client } from '../../config/configurateAPIService';

import queryBuilder from '../../utils/queryBuilder';

export const getNews = async (offset = 0, length = 20, draft = true, filters) => {
  const res = await client.get(queryBuilder(
    '/news',
    {
      adminFind: true,
      offset,
      length,
      draft,
      ...filters,
    },
  ));

  if (res.status !== 200) {
    throw new Error(`Fetch news failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Fetch news failed. Data not returned');
  }

  return res.data;
};

export const createNews = async (data) => {
  const res = await client.post('/news', data);

  if (res.status !== 200) {
    throw new Error(`Create news failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Create news failed. Data not returned');
  }

  return res.data;
};

export const updateNews = async (id, data) => {
  const res = await client.put(`/news/${id}`, data);

  if (res.status !== 200) {
    throw new Error(`Update news failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Update news failed. Data not returned');
  }

  return res.data;
};

export const deleteNews = async (id) => {
  const res = await client.delete(`/news/${id}`);

  if (res.status !== 200) {
    throw new Error(`Delete news failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Delete failed. Data not returned');
  }

  return res.data;
};
