import axios from 'axios';

import * as routes from './routes';

// const url = 'http://ec2-34-243-199-174.eu-west-1.compute.amazonaws.com';

export const client = axios.create({
  // baseURL: dev,
  headers: {
    'Accept-Language': 'ua-UA',
  },
});

export const setupInterceptors = (store, history) => {
  client.interceptors.response.use(res => res, (err) => {
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      history.push(routes.LOGIN);
      return Promise.reject(err);
    }
    return Promise.reject(err);
  });
};

export const setAuthToken = (token) => {
  if (token) {
    client.defaults.headers.common.authorization = token;
  } else {
    delete client.defaults.headers.common.authorization;
  }
};
