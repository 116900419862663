import { createAction } from 'redux-actions';

import * as routes from '../../config/routes';
import * as APIService from './api';
import { setAuthToken } from '../../config/configurateAPIService';
import notifyMessages from '../../config/notifyMessages';
import { saveTokenToStorage, removeTokenFromStorage } from '../../utils/authToken';

export const logInRequest = createAction('LOGIN/REQUEST');
export const logInSuccess = createAction('LOGIN/SUCCESS', l => l, () => ({ redirectPath: routes.ROOT }));
export const logInFailure = createAction('LOGIN/FAILURE');

export const clearUserData = createAction('CLEAR/USERDATA', l => l, () => ({ redirectPath: routes.LOGIN }));

export const logIn = ({ phone, password }) => async (dispatch) => {
  dispatch(logInRequest());
  try {
    const res = await APIService.logIn({ phone, password });
    saveTokenToStorage(res.token);
    setAuthToken(res.token);
    dispatch(logInSuccess());
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      console.log(e.response);
      dispatch(logInFailure({ status: 'error', message: notifyMessages.errors.unauthorized }));
    } else {
      dispatch(logInFailure({ status: 'error', message: 'Щось пішло не так' }));
    }
  }
};

export const logOut = () => (dispatch) => {
  removeTokenFromStorage();
  dispatch(clearUserData());
  Promise.resolve();
};
