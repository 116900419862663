import React from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Upload,
  Tabs,
  notification,
} from 'antd';

import notifyMessages from '../../../config/notifyMessages';

import styles from './News.module.scss';

class NewsForm extends React.Component {
  componentDidMount() {
    this.props.getActives();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      data: { newsId },
      createNews,
      updateNews,
    } = this.props;

    this.props.form.validateFields((err) => {
      if (!err) {
        newsId ? updateNews(newsId, false)() : createNews();
      } else {
        notification.error({
          message: notifyMessages.errors.title,
          description: notifyMessages.errors.emptyTranslationFields,
          placement: 'topLeft',
          duration: 5,
        });
      }
    });
  }

  renderTranslationFields = (lang) => {
    const { form, data: { draft } } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label="Тема">
              {getFieldDecorator(`title_${lang}`, { rules: [{ required: true, message: 'Потрібно заповнити це поле' }] })(
                <Input disabled={!draft} placeholder="Тема" />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label="Текст новини">
              {getFieldDecorator(`body_${lang}`, { rules: [{ required: true, message: 'Потрібно заповнити це поле' }] })(
                <Input.TextArea disabled={!draft} rows={15} placeholder="Текст новини" />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      form,
      actives,
      cancel,
      data: { newsId, coverImageUrl, draft },
      saveNews,
      updateNews,
      deleteNews,
      getActivesFetchStatus,
      selectedNewsId,
      handleChangeFile,
      createNewsFetchStatus,
      updateNewsFetchStatus,
      deleteNewsFetchStatus,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label="Аудиторія">
              {getFieldDecorator('actives', {
                rules: [{
                  required: true,
                  message: 'Потрібно заповнити це поле',
                }],
              })(
                <Select
                  showArrow
                  disabled={!draft}
                  loading={getActivesFetchStatus === 'request'}
                  placeholder="Виберіть аудиторію"
                  maxTagCount={5}
                  mode="multiple"
                >
                  <Select.Option value={0}>Всі</Select.Option>
                  {actives.map(active => (
                    <Select.Option key={active.id} value={active.id}>{active.name}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Дата відправки">
              {getFieldDecorator('startDate', { rules: [{ required: true, message: 'Потрібно заповнити це поле' }] })(
                <DatePicker
                  showTime={{
                    placeholder: 'Вибрати час',
                    format: 'HH:mm',
                  }}
                  disabled={!draft}
                  format="DD-MM-YYYY HH:mm"
                  showToday={false}
                  placeholder="Дата відправки"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Tabs defaultActiveKey="ua">
          <Tabs.TabPane key="ua" tab="UA">
            {this.renderTranslationFields('ua')}
          </Tabs.TabPane>
          <Tabs.TabPane key="ru" tab="RU">
            {this.renderTranslationFields('ru')}
          </Tabs.TabPane>
          <Tabs.TabPane key="en" tab="EN">
            {this.renderTranslationFields('en')}
          </Tabs.TabPane>
        </Tabs>
        <Upload
          accept=".png, .jpg, .jpeg, .giff"
          customRequest={handleChangeFile}
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
          fileList={
            coverImageUrl
          }
          style={{ marginRight: 25 }}
        >
          {draft && (
            <Button>Завантажити фото</Button>
          )}
        </Upload>
        <div className={styles.actions}>
          {draft && (
            <React.Fragment>
              <Button type="primary" htmlType="submit" loading={createNewsFetchStatus === 'request'}>Опублікувати</Button>
              <Button
                type="primary"
                loading={updateNewsFetchStatus === 'request'}
                htmlType="button"
                onClick={newsId ? updateNews(newsId, true) : saveNews}
              >
                Зберегти чернетку
              </Button>
            </React.Fragment>
          )}
          {selectedNewsId && (
            <Button
              type="danger"
              htmlType="button"
              loading={deleteNewsFetchStatus === 'request'}
              onClick={deleteNews(newsId)}
            >
              Видалити
            </Button>
          )}
          <Button htmlType="button" onClick={cancel}>Закрити</Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({
  name: 'news',
  onFieldsChange: (props, changedFields) => {
    props.handleChange(changedFields);
  },
  mapPropsToFields: ({ data }) => ({
    title_ru: Form.createFormField({ ...data.title_ru, value: data.title_ru.value }),
    body_ru: Form.createFormField({ ...data.body_ru, value: data.body_ru.value }),

    title_ua: Form.createFormField({ ...data.title_ua, value: data.title_ua.value }),
    body_ua: Form.createFormField({ ...data.body_ua, value: data.body_ua.value }),

    title_en: Form.createFormField({ ...data.title_en, value: data.title_en.value }),
    body_en: Form.createFormField({ ...data.body_en, value: data.body_en.value }),

    actives: Form.createFormField({ ...data.actives, value: data.actives.value }),
    startDate: Form.createFormField({ ...data.startDate, value: data.startDate.value }),
  }),
})(NewsForm);
