import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
} from 'antd';

import { logIn } from '../actions';
import authFetchStatusSelector from '../selectors';

import styles from './Login.module.scss';

class Login extends React.Component {
  handleSubmint = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.logIn(values);
      }
    });
  }

  render() {
    const { form, authFetchStatus } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Row type="flex" align="middle" justify="center" className={styles.login}>
        <Col span={6}>
          <Form onSubmit={this.handleSubmint} className="login-form">
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: 'Необхідно заповнити номер телефону' }],
              })(
                <Input
                  prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Телефон"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Необхідно заповнити пароль' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Пароль"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button loading={authFetchStatus === 'request'} type="primary" htmlType="submit">Увійти</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  authFetchStatus: authFetchStatusSelector(state),
});

export default compose(
  Form.create({ name: 'loginForm' }),
  connect(mapStateToProps, { logIn }),
)(Login);
