import history from '../history';

/* eslint-disable */
const redirectMiddleware = store => next => (action) => {
  next(action);
  if (action.meta && action.meta.redirectPath) {
    const { meta } = action;
    history.push(meta.redirectPath);
  }
};

export default redirectMiddleware;
