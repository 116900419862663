import { client } from '../../config/configurateAPIService';

import queryBuilder from '../../utils/queryBuilder';

export const getInformations = async (activeId) => {
  const res = await client.get(queryBuilder('/information/get', { activeId }));

  if (res.status !== 200) {
    throw new Error(`Fetch information failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Fetch information failed. Data not returned');
  }

  return res.data;
};

export const createInformation = async (data) => {
  const res = await client.post('/information/create', data);

  if (res.status !== 200) {
    throw new Error(`Create information failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Create information failed. Data not returned');
  }

  return res.data;
};

export const updateInformation = async (id, data) => {
  const res = await client.put(`/information/${id}`, data);

  if (res.status !== 200) {
    throw new Error(`Update information failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Update information failed. Data not returned');
  }

  return res.data;
};

export const deleteInformation = async (id) => {
  const res = await client.delete(`/information/${id}`);

  if (res.status !== 200) {
    throw new Error(`Delete information failed. HTTP status ${res.status}`);
  }

  if (!res.data) {
    throw new Error('Delete information. Data not returned');
  }

  return res.data;
};
