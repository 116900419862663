import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { setupInterceptors, setAuthToken } from './config/configurateAPIService';
import { getTokenFromStorage }  from './utils/authToken';
import createStore from './store/configurateStore';
import rootReducer from './store/rootReducer';
import history from './store/history';

import App from './modules/App';


import 'antd/dist/antd.css';
import './themes/main.scss';

const store = createStore(rootReducer);
setupInterceptors(store, history);

const token = getTokenFromStorage();

if (token) {
  setAuthToken(token);
}

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./modules/App', () => {
    /* eslint-disable-next-line */
    const NextApp = require('./modules/App').default;
    render(NextApp);
  });
}
