import { combineReducers } from 'redux';

import common from '../modules/common/reducers';
import auth from '../modules/Auth/reducers';
import news from '../modules/News/reducers';
import information from '../modules/Informations/reducers';

export default combineReducers({
  common,
  auth,
  news,
  information,
});
