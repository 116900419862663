import { client } from '../../config/configurateAPIService';

export const logIn = async (data) => {
  const res = await client.post('/admin/login', data);

  if (res.status !== 200) {
    throw new Error(`Login failed. HTTP status ${res.status}`);
  }

  return res.data;
};
