import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

const actives = handleActions({
  [actions.getActivesSuccess]: (_, { payload }) => payload.filter(active => active.id !== 0),
}, []);

const getActives = handleActions({
  [actions.getActivesRequest]: () => 'request',
  [actions.getActivesSuccess]: () => 'success',
  [actions.getActivesFailure]: () => 'failure',
}, '');

const fetching = combineReducers({ getActives });

export default combineReducers({ actives, fetching });
