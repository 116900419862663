import React from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Tabs,
  Table,
  Drawer,
  Button,
  Input,
  Icon,
} from 'antd';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

import {
  getInformations,
  createInformation,
  updateInformation,
  deleteInformation,
} from '../actions';
import { getActives } from '../../common/actions';
import { activesSelector, getActivesFetchStatusSelector } from '../../common/selectors';
import {
  informationsSelector,
  getInformationsFetchSelector,
  createInformationFetchSelector,
  updateInformationFetchSelector,
  deleteInformationFetchSelector,
} from '../selectors';

import { uploadFile } from '../../common/api';
import getFileName from '../../../utils/getFileNameFromUrl';

import Sidebar from '../../common/components/Sidebar';
import InformationForm from './InformationForm';

import styles from './Informations.module.scss';

class Informations extends React.Component {
  state = {
    isOpenInfoForm: false,
    selectedInfoId: '',
    informationData: {
      title_ru: { value: '' },
      description_ru: { value: '' },

      title_ua: { value: '' },
      description_ua: { value: '' },

      title_en: { value: '' },
      description_en: { value: '' },

      actives: { value: [] },
      image: '',
      pdf: [],
    },
  }

  async componentDidMount() {
    await this.props.getActives();
    await this.props.getInformations();
  }

  componentDidUpdate(prevProps) {
    const {
      createInformationFetchStatus,
      updateInformationFetchStatus,
      deleteInformationFetchStatus,
    } = this.props;

    const isCreateSuccess = prevProps.createInformationFetchStatus !== 'success' && createInformationFetchStatus === 'success';
    const isUpdateSuccess = prevProps.updateInformationFetchStatus !== 'success' && updateInformationFetchStatus === 'success';
    const isDeleteSuccess = prevProps.deleteInformationFetchStatus !== 'success' && deleteInformationFetchStatus === 'success';

    if (isCreateSuccess || isUpdateSuccess || isDeleteSuccess) {
      this.closeInfoForm();
    }
  }

  openInfoForm = () => this.setState({ isOpenInfoForm: true });

  showInfoInfo = data => () => {
    this.setState({
      isOpenInfoForm: true,
      selectedInfoId: data.id,
      informationData: {
        ...data,
        title_ru: { value: data.title_ru },
        description_ru: { value: data.description_ru },

        title_ua: { value: data.title_ua },
        description_ua: { value: data.description_ua },

        title_en: { value: data.title_en },
        description_en: { value: data.description_en },

        actives: {
          value: data.actives.some(active => active.id === 0)
            ? [0]
            : data.actives.map(active => active.id),
        },
        startDate: { value: moment(data.startDate) },
        pdf: data.pdf
          ? [{
            uid: '-1', name: getFileName(data.pdf), status: 'done', url: data.pdf,
          }]
          : [],
      },
    });
  }

  closeInfoForm = () => {
    this.setState({
      isOpenInfoForm: false,
      selectedInfoId: '',
      informationData: {
        title_ru: { value: '' },
        description_ru: { value: '' },

        title_ua: { value: '' },
        description_ua: { value: '' },

        title_en: { value: '' },
        description_en: { value: '' },

        actives: { value: [] },
        image: '',
        pdf: [],
      },
    });
  }

  createInformation = () => {
    const {
      title_ru,
      description_ru,
      title_ua,
      description_ua,
      title_en,
      description_en,
      actives,
      image,
      pdf,
    } = this.state.informationData;

    this.props.createInformation({
      title_ru: title_ru.value,
      description_ru: description_ru.value,

      title_ua: title_ua.value,
      description_ua: description_ua.value,

      title_en: title_en.value,
      description_en: description_en.value,

      actives: actives.value.includes(0)
        ? [{ id: 0 }]
        : actives.value.map(id => ({ id })),
      image,
      pdf: pdf[0] ? pdf[0].url : '',
    });
  }

  updateInformation = (id) => {
    const {
      title_ru,
      description_ru,
      title_ua,
      description_ua,
      title_en,
      description_en,
      actives,
      image,
      pdf,
    } = this.state.informationData;

    this.props.updateInformation(id, {
      title_ru: title_ru.value,
      description_ru: description_ru.value,

      title_ua: title_ua.value,
      description_ua: description_ua.value,

      title_en: title_en.value,
      description_en: description_en.value,

      actives: actives.value.includes(0)
        ? [{ id: 0 }]
        : actives.value.map(id => ({ id })),
      image,
      pdf: pdf[0] ? pdf[0].url : '',
    });
  }

  deleteInformation = id => () => {
    this.props.deleteInformation(id);
  }

  handleChange = (changedFields) => {
    this.setState(({ informationData }) => ({
      informationData: { ...informationData, ...changedFields },
    }));
  }

  handleChangeFile = type => async ({
    file,
    onSuccess,
  }) => {
    const data = new FormData();

    data.append('file', file);
    this.setState(state => ({
      informationData: {
        ...state.informationData,
        [type]: [{ uid: '-1', name: file.name, status: 'uploading' }],
      },
    }));

    try {
      const params = {
        name: file.name,
        type: 'postFile',
      };
      const { url } = await uploadFile(data, params);

      this.setState(state => ({
        informationData: {
          ...state.informationData,
          [type]: [{
            uid: '-1', name: file.name, status: 'done', url,
          }],
        },
      }));
      onSuccess();
    } catch (e) {
      console.error(e);
    }
  }

  // NOTE: see antd docs for table
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginBottom: 5 }}>Мінімум 3 символи</span>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => (selectedKeys[0].length > 2 ? this.handleSearch(selectedKeys, confirm) : {})}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Пошук
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Скинути
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    const [findTitle] = selectedKeys;

    if (findTitle.length > 2) {
      confirm();
    }
  }

  handleReset = (clearFilters) => {
    clearFilters();
  }

  renderAddInfoButton = () => (
    <Button type="primary" onClick={this.openInfoForm}>
      <Icon type="plus" />
      Добавити розділ
    </Button>
  )

  render() {
    const {
      informations,
      getActivesFetchStatus,
      getInformationsFetchStatus,
      createInformationFetchStatus,
      updateInformationFetchStatus,
      deleteInformationFetchStatus,
    } = this.props;
    const {
      informationData,
      selectedInfoId,
      isOpenInfoForm,
    } = this.state;

    const columns = [
      {
        title: 'Розділ',
        dataIndex: 'title_ua',
        key: 'title_ua',
        ...this.getColumnSearchProps('title_ua'),
        sorter: (a, b) => a.title_ua.localeCompare(b.title_ua),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Аудиторія',
        dataIndex: 'actives',
        key: 'actives',
        ellipsis: true,
        // filters: this.props.actives.map(active => ({
        //   ...active,
        //   text: active.name,
        //   value: active.id
        // })),
        // onFilter: (value, record) => record.active.id === value,
        render: actives => (
          actives.some(active => active.id === 0)
            ? 'Всі'
            : actives.map(active => active.name).join(', ')
        ),
      },
    ];

    return (
      <React.Fragment>
        <Layout style={{ height: '100%' }}>
          <Sidebar />
          <Layout>
            <Layout.Content className={styles.news}>
              <Tabs defaultActiveKey="saved" tabBarExtraContent={this.renderAddInfoButton()}>
                <Tabs.TabPane key="saved" tab="Інформація">
                  <Table
                    columns={columns}
                    dataSource={informations}
                    tableLayout="fixed"
                    pagination={{ pageSize: 20, size: 'small' }}
                    scroll={{ y: 'calc(100vh - 220px)' }}
                    loading={[getActivesFetchStatus, getInformationsFetchStatus, createInformationFetchStatus].includes('request')}
                    className={styles.table}
                    rowKey={() => uniqueId()}
                    onRow={record => ({ onClick: this.showInfoInfo(record) })}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Layout.Content>
          </Layout>
        </Layout>
        <Drawer
          title="Інформація"
          width="50%"
          visible={isOpenInfoForm}
          bodyStyle={{ height: 'calc(100% - 55px)', position: 'relative' }}
          onClose={this.closeInfoForm}
        >
          <InformationForm
            selectedInfoId={selectedInfoId}
            data={informationData}
            handleChange={this.handleChange}
            handleChangeFile={this.handleChangeFile}
            cancel={this.closeInfoForm}
            actives={this.props.actives}
            getActives={this.props.getActives}
            getActivesFetchStatus={getActivesFetchStatus}
            createInformation={this.createInformation}
            updateInformation={this.updateInformation}
            deleteInformation={this.deleteInformation}
            createInformationFetchStatus={createInformationFetchStatus}
            updateInformationFetchStatus={updateInformationFetchStatus}
            deleteInformationFetchStatus={deleteInformationFetchStatus}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  informations: informationsSelector(state),
  actives: activesSelector(state),
  getInformationsFetchStatus: getInformationsFetchSelector(state),
  createInformationFetchStatus: createInformationFetchSelector(state),
  updateInformationFetchStatus: updateInformationFetchSelector(state),
  deleteInformationFetchStatus: deleteInformationFetchSelector(state),
  getActivesFetchStatus: getActivesFetchStatusSelector(state),
});

export default connect(mapStateToProps, {
  getInformations,
  createInformation,
  updateInformation,
  deleteInformation,
  getActives,
})(Informations);
