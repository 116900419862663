import { notification } from 'antd';

import notifyMessages from '../../config/notifyMessages';

/* eslint-disable */
const redirectMiddleware = store => next => (action) => {
  next(action);

  const { payload } = action;

  if (payload && payload.status === 'error') {
    notification.error({
      message: notifyMessages.errors.title,
      description: payload.message,
      placement: 'topLeft',
    });
  }
};

export default redirectMiddleware;
